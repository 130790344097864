ko.bindingHandlers.addressAutocomplete = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor();
        var allBindings = allBindingsAccessor();
        if (google !== undefined && google) {
            if (value.target) {
                var target = value.target;
                var types = value.types ? value.types : 'geocode';
            }

            if (value.element) {
                element = document.getElementById(value.element)
            }

            //@FIXME forzato autocompletamento di tipo geocode
            var options = {
                types: ['geocode'],
                componentRestrictions: {country: 'it'}
            };
            ko.utils.extend(options, allBindings.autocompleteOptions);

            var autocomplete = new google.maps.places.Autocomplete(element, options);
            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                if (!place || !place.geometry) {
                    return;
                }
                target(place);
                //@TODO trovare soluzioen piu elegante e implementare ovunque
                if (value.event) {
                    value.event();
                }
            });
        }
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        ko.bindingHandlers.value.update(element, valueAccessor);
    }
};