function SocialShareModule (text, url) {
  var self = this

  self.url = ko.observable(url)
  self.text = ko.observable(text)
  self.textFacebook = ko.observable(text)
  self.textWhatsapp = ko.observable(text)
  self.textSms = ko.observable(text)
  self.textTwitter = ko.observable(text)

  self.getText = function (social, text) {
    if (!text) {
      text = self.text() ? self.text() : 'TrovaciGusto.com'

      switch (social) {
        case 'facebook':
          text = self.textFacebook()
          break
        case 'twitter':
          text = self.textTwitter()
          break
        case 'whatsapp':
          text = self.textWhatsapp()
          break
        case 'sms':
          text = self.textSms()
          break
      }
    }
    return text
  }

  self.getShareText = function (social, text) {
    text = self.getText(social, text)

    return encodeURIComponent(text)
  }

  self.getShareUrl = function () {
    if (!self.url())
      return window.location.origin
    if (self.url().indexOf(window.location.origin) >= 0)
      return self.url()

    return window.location.origin + self.url()
  }

  self.shareFB = function () {
    if (isGonative())
      gonative.share.sharePage();
    if (isMobile()) {
      window.location.href = 'https://www.facebook.com/sharer/sharer.php?app_id=836519186411810&sdk=joey&display=popup&ref=plugin&src=share_button&u=' + self.getShareUrl()
    } else {
      FB.ui({
        method: 'share',
        quote: self.getText(),
        hashtag: '#trovacigusto',
        href: self.getShareUrl(),
      }, function (response) {
        if (response) {

        }
      })
    }
  }

  self.shareWhatsapp = function () {
    window.open('https://api.whatsapp.com/send?text=' + self.getShareText() + ': ' + self.getShareUrl(), 'whatsapp-share',
      'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
  }

  self.shareTwitter = function () {
    window.open('https://twitter.com/share?url=' + self.getShareUrl() + '&text=' + self.getShareText(), 'twitter-share',
      'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
  }

  self.shareSMS = function () {
    window.open('sms:?body=' + self.getShareText() + ': ' + self.getShareUrl(), 'sms-share',
      'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
  }

  self.fbSend = function () {
    if (isGonative())
      gonative.share.sharePage();
    if (isMobile()) {
      window.open('https://www.facebook.com/sharer/sharer.php?app_id=836519186411810&sdk=joey&display=popup&ref=plugin&src=share_button&u=' + self.getShareUrl())
    } else {
      FB.ui({
        method: 'send',
        link: self.getShareUrl()
      })
    }
  }

  return self
}