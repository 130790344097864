function getBaseURL() {
  if (location.hostname.includes('gustok.com')) {
    return 'https://gustok.com'
  }
  if (!location.hostname.includes('trovacigusto.com')) {
    return 'https://trovacigusto.studio'
  }
  return 'https://trovacigusto.com'
}

/**
 *
 *  HTTP verb.
 *  Endpoint.
 * @param method
 * @param url
 * @param data
 *  Data to be sent. It should not be json-encoded.
 * @param callback
 *  Optional callback.
 * @param preventRefresh
 * @param timeout
 * @return
 *  Deferred object.
 */
function rest (method, url, data, callback, preventRefresh, timeout) {
  if (preventRefresh) {
    return $.Deferred().promise()
  }

  var auth_token = getCookie('auth_token')

  // Wrap the csrf token in the payload.
  var payload = {}

  if (method !== 'GET') {
    payload = {
      data: data,
      _token: csrfToken
    }
    // Encode the payload.
    payload = ko.toJSON(payload)
  } else {
    payload = { _token: csrfToken }
  }

  // This fixes trailing slashes issue with Laravel routing.
  // https://github.com/laravel/framework/issues/1438
  url.replace(/\/$/, '')
  // Check for callback.
  if (callback) {
    if (typeof (callback) === 'object') {

    }
  }

  if (location.hostname.includes('trovacigusto.studio') && window.TROVACIGUSTO) {
    timeout = 0
  }

  if (window.TROVACIGUSTO && window.TROVACIGUSTO.app_url)
    url = new URL(url, window.TROVACIGUSTO.app_url)
  else
    url = new URL(url, getBaseURL())

  // Return plain ajax return.
  return $.ajax({
    type: method,
    url: url.href,
    data: payload,
    success: callback,
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    headers: {'X-Auth-Token': auth_token},
    cache: false,
    timeout: timeout ? timeout : 0
  }).fail(function (jqXHR, textStatus) {
    if (jqXHR.status === 401) {
      return refresh().then(function (jqXHR) {
        if (!jqXHR.success) {
          return $.Deferred().promise()
        }
        return rest(method, url, data, callback, true)
      })
    }

    return $.Deferred().fail()
  })
}

function refresh () {
  return $.post('/token/refresh', { _token: csrfToken })
}

function getCookie (cname) {
  var name = cname + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1)
    if (c.indexOf(name) !== -1) return c.substring(name.length, c.length)
  }
  return ''
}

function getPnotifyOptions (title, type, message, hide, timeout) {
  if (!title) {
    switch (type) {
      case 'error':
      case 'danger':
        title = 'Errore'
        type = 'error'
        break
      case 'info':
        title = 'Informazioni'
        break
      case 'notice':
      case 'warning':
        title = 'Attenzione'
        type = 'notice'
        break
      case 'success':
        title = 'OK!'
        break
      default:
        title = 'Ok!'
        break
    }
  }

  return {
    title: title,
    text: message,
    textTrusted: true,
    hide: typeof hide !== 'undefined' ? hide : true,
    type: typeof type !== 'undefined' ? type : 'success',
    delay: typeof timeout !== 'undefined' ? timeout : 5000,
    modal: false,
    sticker: false,
    closer: true,

  }
}

function getPnotifyStack (opts, name) {
  if (typeof name === 'undefined')
    return opts

  if (name === 'bottom') {
    opts.stack = new PNotify.Stack({
      dir1: 'up',
      firstpos1: 0,
      spacing1: 0
    })
    opts.addClass = 'stack-bar-bottom'
    opts.shadow = false
    opts.width = '100%'
    opts.closer = false
  }

  return opts
}

function pNotify (message, type, timeout, title, hide, stackName) {
  var opts = getPnotifyOptions(title, type, message, hide, timeout)
  opts = getPnotifyStack(opts, stackName)

  PNotify.defaultModules.set(PNotifyBootstrap3, {})
  PNotify.defaultModules.set(PNotifyFontAwesome4, {})
  PNotify.defaultModules.set(PNotifyMobile, {})

  var notice = new PNotify.alert(opts)

  /*    notice.on('click', function () {
          notice.close();
      });*/
  return notice
}